/*
 * @Autor: lh
 * @Date: 2023-01-06 11:03:20
 * @LastEditors: lh
 * @LastEditTime: 2023-07-24 10:32:09
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import rem from "./static/js/rem"
import './static/js/vantUi.js'
import { VueJsonp } from 'vue-jsonp'
import sdk from "@/static/js/sdk"
import bus from "@/static/js/bus"
import {api2} from "@/static/js/http"
Vue.use(VueJsonp)

Vue.config.productionTip = false
Vue.prototype.$sdk = sdk;
Vue.prototype.$bus = bus;
Vue.prototype.$imghost = api2;

Vue.filter('fensifil',function(num){
  let e = Number(num);
  if(e < 1000){
    return e;
  }else if( e >= 1000 && e < 10000){
    let num = (e/1000).toFixed(1)
    return num +'k'
  }else if(e >= 10000 && e < 100000000) {
    let num = (e/10000).toFixed(1)
    return num +'万'
  }else {
    let num = (e/100000000).toFixed(1)
    return num +'亿'
  }
})


rem()


  window.onload = function(){
      document.onkeydown = function () { ///禁用f12
          if (window.event && window.event.keyCode == 123) {
              // event.keyCode = 0;
              // event.returnValue = false;
              if(location.host.indexOf('localhost') ==-1){
                window.event.returnValue = false;
              }
              
          }
          // if (window.event && window.event.keyCode == 13) {
          //     window.event.keyCode = 505;
          // }
          // if (window.event && window.event.keyCode == 8) {
          //     window.event.returnValue = false;
          // }

      }
      document.oncontextmenu = function (event) { // 禁用右键菜单
          if (window.event) {
              event = window.event;
          }
          try {
          var the = event.srcElement;
          if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")) {
              return false;
          }
              return true;
          } catch (e) {
              return false;
          }
      }
  }


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
