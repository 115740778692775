/*
 * @Autor: lh
 * @Date: 2023-04-24 10:23:03
 * @LastEditors: lh
 * @LastEditTime: 2023-05-06 10:29:43
 * @Description: 
 */
const state = {
    img_ym:'',
    bilingual:1,// 双语维护 1 为中文, 2为日文
    tabType:1, // 当前选中tab类型
    tabList_chs:[
        {name:'Doris营养均衡主粮',class:'first odd',url:'/dog',
        child:[
            // {name:'元气满满提高免疫力主粮',url:'/series',img:require("@/static/img/dog/combo_dog_dry2_400.png"),class:'first odd'},
            // {name:'护眼护肤主粮',url:'/dog',img:require("@/static/img/dog/combo_dog_yawaraka_soft1_400.png"),class:'last even'},
            // {name:'抑制肥胖健康主粮',url:'/dog',img:require("@/static/img/dog/combo_dog_yawaraka_soft1_400.png"),class:'last even'},
        ] 
        },
        {name:'营养均衡多样化主粮',class:'even',url:'/dog',child:[
            // {name:'干（脆）',url:'/dog',img:require("@/static/img/dog/combo_pure_dog_dry1_400.png"),class:'first odd'},
            // {name:'点心，点心',url:'/dog',img:require("@/static/img/dog/combo_pure_mogumogood1_400.png"),class:'last even'},
        ] },
        // {name:'Doris零食/辅助食品',class:'last odd',url:'/dog',child:[
        //     // {name:'零食',url:'/dog',img:require("@/static/img/dog/combo_present_dog_oyatsu1_400.png"),class:'first last odd'},
        //     // {name:'辅助食品',url:'/dog',img:require("@/static/img/dog/combo_present_dog_oyatsu1_400.png"),class:'first last odd'},
        // ] }
    ],
    tabList_jp:[
        {name:'Dorisバラプリごはん',class:'first odd',url:'/dog',
            child:[
                // {name:'元気いっぱい免疫力UPごはん',url:'/series',img:require("@/static/img/dog/combo_dog_dry2_400.png"),class:'first odd'},
                // {name:'眼と皮膚ケアのごはん',url:'/dog',img:require("@/static/img/dog/combo_dog_yawaraka_soft1_400.png"),class:'last even'},
                // {name:'肥満解消ヘルシーごはん',url:'/dog',img:require("@/static/img/dog/combo_dog_yawaraka_soft1_400.png"),class:'last even'},
            ] 
        },
        {name:'バラプリおうちごはん',class:'even',url:'/dog',child:[
            // {name:'ドライ（カリカリ）',url:'/dog',img:require("@/static/img/dog/combo_pure_dog_dry1_400.png"),class:'first odd'},
            // {name:'おやつ',url:'/dog',img:require("@/static/img/dog/combo_pure_mogumogood1_400.png"),class:'last even'},
        ] },
        // {name:'Dorisおやつ/補助食品',class:'last odd',url:'/dog',child:[
        //     // {name:'おやつ',url:'/dog',img:require("@/static/img/dog/combo_present_dog_oyatsu1_400.png"),class:'first last odd'},
        //     // {name:'補助食品',url:'/dog',img:require("@/static/img/dog/combo_present_dog_oyatsu1_400.png"),class:'first last odd'},
        // ] }
    ]
}
const mutations = {
    setG_Img_HOST(commit,host){
        commit.img_ym = host;
    },
    // 设置双语
    setG_bilingual(commit,type){
        commit.bilingual = type;
    },
    // 设置tab类型
    setG_TabType(commit,type){
        commit.tabType = type;
    },
    // 设置tab列表的中文数组
    setTab_Chs_List(commit,value){
        commit.tabList_chs = value
    },
    // 设置tab列表的日文数组
    setTab_Jp_List(commit,value){
        commit.tabList_jp = value
    },
}
const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
}