/*
 * @Autor: lh
 * @Date: 2023-04-08 12:01:46
 * @LastEditors: lh
 * @LastEditTime: 2023-04-12 10:12:11
 * @Description: 
 */
export default {
    back(){
        // 返回
        window.AndroidJs.goback();
    },
    getToken(){
        // 获取用户token
        window.AndroidJs.getToken();
    },
    pupdetails(){
        console.log('调用安卓--pupdetails');
        // 发布动态
        window.AndroidJs.pupdetails();
    },
    editMsg(){
        // 编辑用户信息
        window.AndroidJs.editMsg();
    },
    goui(urls,mark){ // 跳转链接
        let url = 'https://trends.biduaaa.vip';
        url = url + urls
        console.log(url,mark);
        window.AndroidJs.goui(url,mark)
    },
    share(){
        console.log('调用安卓方法 share');
        // 分享
        window.AndroidJs.share();
    },
    editMsg1(callback){
        // 背景
        console.log('--editMsg1--');
        window.AndroidJs.editMsg1();
    }
}