/*
 * @Autor: lh
 * @Date: 2023-01-06 14:42:22
 * @LastEditors: lh
 * @LastEditTime: 2023-07-19 10:12:49
 * @Description: 
 */
import { apiPost, apiGet } from "@/static/js/http";

// 通过ID获取产品详情
export function getGoodsByIdProduct(params) {
    return apiPost({
        url: "/api/Product/getGoodsById",
        params
    })
}
// 获取某个系列的商品
export function getSeriesGoodsProduct(params) {
    return apiPost({
        url: "/api/Product/getSeriesGoods",
        params
    })
}



// 获取全部商品
export function getAllGoodsProduct(params) {
    return apiGet({
        url: "/api/Product/getAllGoods",
        params
    })
}
// 获取全部产品系列
export function getAllSeriesProduct(params) {
    return apiGet({
        url: "/api/Product/getAllSeries",
        params
    })
}
// 获取全部产品tag
export function getAllTagsProduct(params) {
    return apiGet({
        url: "/api/Product/getAllTags",
        params
    })
}
// 通过ID获取产品系列详情
export function getSeriesByIdProduct(params) {
    return apiPost({
        url: "/api/Product/getSeriesById",
        params
    })
}
// 获取轮播列表
export function showBannerList(params) {
    return apiGet({
        url: "/api/Banner/showBanner",
        params
    })
}
// 获取前端下拉菜单 系列 + 产品卖点数据
export function getMenuSeries(params) {
    return apiGet({
        url: "/api/Product/getMenuSeries",
        params
    })
}
// 查看公告详情
export function getNoticeDetail(params) {
    return apiPost({
        url: "/api/Notice/getNoticeDetail",
        params
    })
}
// 获取客户心声
export function getListComment(params) {
    return apiGet({
        url: "/api/Comment/getList",
        params
    })
}
// 提交 联系我们 调查表
export function saveContactInfo(params) {
    return apiGet({
        url: "/api/Contact/saveContactInfo",
        params
    })
}
// 提交 商业合作 调查表
export function saveCooperationInfo(params) {
    return apiPost({
        url: "/api/Cooperation/saveCooperationInfo",
        params
    })
}
// 首页推荐产品
export function recommendGoods(params) {
    return apiPost({
        url: "/api/Product/recommend",
        params
    })
}
// 系列页面 筛选产品 所有产品列表
export function getGoods4SeriesPage(params) {
    return apiPost({
        url: "/api/Product/getGoods4SeriesPage",
        params
    })
}
// 某一个 tag_id 查 所有包含他的产品（小tag点击跳转列表页）
export function getGoodsByTag(params) {
    return apiPost({
        url: "/api/Product/getGoodsByTag",
        params
    })
}
// 获取 公告 简略信息(用于列表页和公告的首页方框中)
export function getNoticeBrief(params) {
    return apiGet({
        url: "/api/Notice/getNoticeBrief",
        params
    })
}
// 按type查 对应的 公告列表
export function getNoticeListByType(params) {
    return apiPost({
        url: "/api/Notice/getNoticeListByType",
        params
    })
}

// 获取公司信息
export function getCompanyGetInfo(params) {
    return apiGet({
        url: "/api/Company/getInfo",
        params
    })
}