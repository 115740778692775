/*
 * @Autor: lh
 * @Date: 2022-03-23 17:43:27
 * @LastEditors: lh
 * @LastEditTime: 2023-07-28 11:09:34
 * @Description: 
 */
import Vue from 'vue'
import 'vant/lib/index.css';
import '@vant/touch-emulator';
// import Vant from 'vant';

import {
    Popover,
    Button,
    Field,
    Dialog,
    Icon,
    Swipe,
    SwipeItem,
    // List,
    Empty,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Switch,
    RadioGroup,
    Radio,
    Lazyload,
    // Uploader,
    DatetimePicker,
    Popup,
    // CountDown,
    NoticeBar,
    PullRefresh,
    Overlay,
    Loading,
    DropdownMenu, DropdownItem,
    Form,
    Image as VanImage,
    Area ,
    Picker ,
    Skeleton,
    Card ,
    Collapse, CollapseItem,
} from 'vant';

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Card);
Vue.use(Skeleton);
Vue.use(Picker);
Vue.use(Area);
Vue.use(Popover);
Vue.use(VanImage);
Vue.use(Form);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Overlay);
// Vue.use(Vant);
Vue.use(PullRefresh);
Vue.use(NoticeBar);
// Vue.use(CountDown);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
// Vue.use(List);
Vue.use(Empty);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(RadioGroup);
// Vue.use(Uploader);
Vue.use(DatetimePicker);
Vue.use(Popup);
// Vue.use(Area);
Vue.use(Loading);
