/*
 * @Autor: lh
 * @Date: 2023-03-31 11:07:16
 * @LastEditors: lh
 * @LastEditTime: 2023-04-25 18:42:43
 * @Description: 
 */
import Vue from 'vue'
import Vuex from 'vuex'
//数据长存
import createPersistedState from 'vuex-persistedstate'
import bidu from "./index/bidu"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    bidu
  },
  getters:{
    imghost(state) {
      return state.bidu.img_ym
    }
  },
  plugins:[createPersistedState({
    // 缓存数据时的key
    key: 'bidu-web-store',
    // 需要缓存的模块
    paths: ['bidu'],
  })],
})
