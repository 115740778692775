/*
 * @Autor: lh
 * @Date: 2023-01-06 11:03:20
 * @LastEditors: lh
 * @LastEditTime: 2023-07-21 10:49:57
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
// import {changeJSON2QueryString} from "@/static/js/common"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import('@/views/home/index.vue')
  },
  {
    path: '/middle',
    name: 'middle',
    redirect:'/dog',
    component: ()=> import('@/components/common/middle.vue'),
    children:[
      {
        path: '/dog',
        name: 'dog',
        component: ()=> import('@/views/dog/index.vue'),
        meta:{
          type:1
        }
      },
      {
        path: '/series',
        name: 'series',
        component: ()=> import('@/views/series/index.vue'),
        meta:{
          type:2
        }
      },
      {
        path: '/series/bs',
        name: 'series_bs',
        component: ()=> import('@/views/series/index_2.vue'),
        meta:{
          type:2
        }
      },
      {
        path: '/series/new',
        name: 'series_new',
        component: ()=> import('@/views/series/index_3.vue'),
        meta:{
          type:2
        }
      },
      {
        path: '/series/details',
        name: 'series_details',
        component: ()=> import('@/views/series/details.vue'),
        meta:{
          type:2
        }
      },
      {
        path: '/series/books',
        name: 'series_books',
        component: ()=> import('@/views/series/books.vue'),
        meta:{
          type:1
        }
      },
      {
        path: '/dog/search',
        name: 'dog_search',
        component: ()=> import('@/views/search/index.vue'),
        meta:{
          type:3
        }
      },
      {
        path: '/dog/voice',
        name: 'dog_voice',
        component: ()=> import('@/views/voice/index.vue'),
        meta:{
          type:4
        }
      },
      {
        path: '/dog/shop/details/:id',
        name: 'dog_shop_details',
        component: ()=> import('@/views/dog/shopDetails.vue'),
        meta:{
          type:1
        }
      },
      {
        path: '/dog/seekadvice',
        name: 'seekadvice',
        component: ()=> import('@/views/dog/seekadvice/index.vue'),
        meta:{
          type:5
        }
      },
      {
        path: '/cooperation',
        name: 'cooperation',
        component: ()=> import('@/views/cooperation/index.vue'),
        meta:{
          type:6
        }
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: ()=> import('@/views/introduce/index.vue'),
        meta:{
          type:7
        }
      },
      {
        path: '/notice',
        name: 'notice',
        component: ()=> import('@/views/notice/list.vue'),
        meta:{
          type:1
        }
      },
      {
        path: '/notice/details',
        name: 'notice_details',
        component: ()=> import('@/views/notice/details.vue'),
        meta:{
          type:1
        }
      },
      // {
      //   path: '/dog/seekadvice/form1',
      //   name: 'dog_seekadvice_form1',
      //   component: ()=> import('@/views/dog/seekadvice/form1.vue')
      // },
    ]
  },
  {
    path: '/dog/seekadvice/form1',
    name: 'dog_seekadvice_form1',
    component: ()=> import('@/views/dog/seekadvice/form1.vue'),
    meta:{
      type:1
    }
  },
  {
    path: '/dog/seekadvice/form2',
    name: 'dog_seekadvice_form2',
    component: ()=> import('@/views/dog/seekadvice/form2.vue'),
    meta:{
      type:1
    }
  },
  {
    path: '/dog/seekadvice/form3',
    name: 'dog_seekadvice_form3',
    component: ()=> import('@/views/dog/seekadvice/form3.vue'),
    meta:{
      type:1
    }
  },
  {
    path:'*',
    name:'404',
    redirect:'/',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,form,next)=>{
  // console.log(to,form);
  // let url = 'awy.web.test.ssche.cn';

  // if(window.location.hostname == url){
  //   let locationto = 'http://'+url+to.path+changeJSON2QueryString(to.query)
  //   window.AndroidJs.goui(locationto,to.name)
  // }else{
  //   next()
  // }
  window.scrollTo(0,0)
  next()
})
router.afterEach((to,form)=>{
  // console.log(to);
  if(to.meta.type){
    store.commit('bidu/setG_TabType',to.meta.type)
  }
  
  // window.scrollTo(0,0)
})

export default router
