/*
 * @Autor: lh
 * @Date: 2022-02-25 14:32:18
 * @LastEditors: lh
 * @LastEditTime: 2023-08-04 09:58:32
 * @Description: 封装请求方法
 */
import axios from 'axios'

import store from '@/store'
import { Toast } from "vant";
import {getQueryString} from "./common"


// var api = "http://gl.itong.tech"
// export const api2 = "http://gl.itong.tech"
// var api = "http://dorisadmin.cbecology.com"
// export const api2 = "http://dorisadmin.cbecology.com"

var api = "https://gl.dorispets.com"
export const api2 = "https://gl.dorispets.com"

let isAn = window.AndroidJs ? window.AndroidJs.getToken() :'';
let token = isAn || store.getters.token || getQueryString('token') || '' //|| 



// post 封装方法
export function apiPost(parameter) {
    let headers = parameter.headers || {
        'token': token
    };
    console.log(isAn,'----token');
    return new Promise(function (resolve, reject) {
        let axiosObj = {
            method: "post",
            url: api+ parameter.url,
            params: parameter.params,
            // data:parameter.params,
            headers: headers,
        }
        if(parameter.formdata) axiosObj.data = parameter.params
        axios(axiosObj).then(res => {
            resolve(res);
        }).catch(err => {
            // console.log(err,'-------')
            reject(err);
            try {
                if (err.data.msg) {
                    Toast(err.data.msg);
                }
            } catch (error) {

            }
        });
    });
}
export function apiPostNew(parameter) {
    let headers = parameter.headers || {
        'token': token
    };
    console.log(isAn,'----token');
    return new Promise(function (resolve, reject) {
        let axiosObj = {
            method: "post",
            url: api+ parameter.url,
            params: parameter.params,
            data:parameter.params,
            headers: headers,
        }
        // if(parameter.formdata) axiosObj.data = parameter.params
        axios(axiosObj).then(res => {
            resolve(res);
        }).catch(err => {
            // console.log(err,'-------')
            reject(err);
            try {
                if (err.data.msg) {
                    Toast(err.data.msg);
                }
            } catch (error) {

            }
        });
    });
}
// get封装方法
export function apiGet(parameter) {
    let headers = parameter.headers || {
        // || '0x19F8Ca665a84Cd59e92755D8D63fC0eC538a4246'
        'token': token//isAn || store.getters.token || getQueryString('token') || '' //|| '0x28b0C34177BC86312393D487613C31129cABc817',
        // "referer":'https://m.ppcontract.cloud/',
        // 'host':"m.ppcontract.cloud"
    };
    console.log(isAn,'----token');
    return new Promise(function (resolve, reject) {
        axios.get(
            api + parameter.url,
            {
                params: parameter.params,
                headers: headers,
            }
        ).then(res => {
            resolve(res);
        }).catch(err => {
            console.log(err,'--')
            reject(err);
            try {
                if (err.data.msg) {
                    Toast(err.data.msg);
                }
            } catch (error) {

            }
        });
    });

}
