<!--
 * @Autor: lh
 * @Date: 2023-01-06 11:03:20
 * @LastEditors: lh
 * @LastEditTime: 2023-07-28 10:51:51
 * @Description: 
-->
<template>
  <div id="app" :class="{'chs_app':$store.state.bidu.bilingual == 1}">
    <router-view></router-view>
  </div>
</template>
<script>
import {getMenuSeries} from "@/api/home/index"
import {deepClone} from "@/static/js/common"
export default {
  data() {
    return {};
  },
  mounted() {
    this.init()
  },
  methods:{
    init() { // 初始化
      getMenuSeries().then(res=>{
        if(res.data.code == 1){
          let data = res.data.data;
          let dataValue = Object.values(data)
          var seriesUrl = ['/series','/series/bs','/series/new']
          // let tab_chs = dataValue.map((item,index)=>{
          //   item.name = item.series_name
          //   item.img = this.$imghost + item.series_image
          //   item.url = '/dog'
          //   item.class = 'first odd'
          //   item.child = item.items.map(it=>{
          //     return {name:'dasdas',//it.item_name,
          //       id:it.item_id,url:`${seriesUrl[index]}?id=${it.item_id}`,img:this.$imghost+it.item_image.split(',')[0],class:'first odd'} 
          //   })
          //   return item
          // })
          // let tab_jp = dataValue.map((item,index)=>{
          //   item.name = item.series_name_jp
          //   item.img = this.$imghost+item.series_image
          //   item.url = '/dog'
          //   item.class = 'first odd'
          //   item.child = item.items.map(it=>{
          //     return {name:it.item_name_jp,id:it.item_id,url:`${seriesUrl[index]}?id=${it.item_id}`,img:this.$imghost+it.item_image.split(',')[0],class:'first odd'} 
          //   })
          //   return item
          // })
          let tab_chs = [];
          let tab_jp = [];
          const chs_fn = (items,index)=>{
            let item = deepClone(items)
            console.log(item);
            item.name = item.series_name
            item.img = this.$imghost + item.series_image
            item.img_jp = this.$imghost + item.series_image_jp
            item.url = '/dog'
            item.class = 'first odd'
            item.child = item.items.map(it=>{
              return {name:it.item_name,
                id:it.item_id,url:`${seriesUrl[index]}?id=${it.item_id}&series_id=${item.series_id}`,img:this.$imghost+it.item_image.split(',')[0],class:'first odd'} 
            })
            return item
          }
          const jp_fn = (items,index) =>{
            let item = deepClone(items)
            item.name = item.series_name_jp
            item.img = this.$imghost+item.series_image
            item.img_jp = this.$imghost + item.series_image_jp
            item.url = '/dog'
            item.class = 'first odd'
            item.child = item.items.map(it=>{
              return {name:it.item_name_jp,id:it.item_id,url:`${seriesUrl[index]}?id=${it.item_id}&series_id=${item.series_id}`,img:this.$imghost+it.item_image.split(',')[0],class:'first odd'} 
            })
            return item
          }
          dataValue.forEach((item,index)=>{
            
            let chs_item = chs_fn(item,index)
            let jp_item = jp_fn(item,index)
            tab_chs.push( chs_item )
            tab_jp.push( jp_item )
          })
          console.log(tab_chs,tab_jp);
          this.$store.commit('bidu/setTab_Chs_List',tab_chs)
          this.$store.commit('bidu/setTab_Jp_List',tab_jp)
        }
      })
    }
  }
};
</script>
<style lang="scss">
@import "./static/css/common.css";

html{
	height: 100%;
  font-weight: 400;
}

body{
	height: 100%;
  font-size: 15px;
}

img{
	max-width: 100%;	
}
#app{
  height: 100%;
}
.mt20v{
  margin: 20vw auto 0;
  .van-loading__spinner{
    display: block;
    margin: 0 auto;
  }
}
.isLoadingbox.isLoadingbox{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.inheritbox{
  color: inherit;
  font-size: inherit;
  font-style: inherit;
}
.imgbj{
  display: block;width: 100%;
}
.tanbox{
    position: fixed;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;
}
.tanbox_bj{
    position: absolute;left: 0;top: 0;width: 100%;height: 100vh;background: rgba(0, 0, 0, 0.6);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // font-family: Poppins-Regular, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif;
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
}

#nprogress {
  pointer-events: none
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: .04rem
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 2rem;
  height: 100%;
  box-shadow: 0 0 .2rem #29d, 0 0 .1rem #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -.08rem);
  -ms-transform: rotate(3deg) translate(0, -.08rem);
  transform: rotate(3deg) translateY(-.08rem)
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: .3rem;
  right: .3rem
}

#nprogress .spinner-icon {
  width: .36rem;
  height: .36rem;
  box-sizing: border-box;
  border: solid .04rem transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner .4s linear infinite;
  animation: nprogress-spinner .4s linear infinite
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute
}
@keyframes downto_upshow {
    0%{
        transform: translateY(100%);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes downto_uphide {
    0%{
        transform: translateY(0);
        opacity: 1;
    }
    100%{
        transform: translateY(100%);
        opacity: 0;
    }
}
@keyframes smallof_big {
  0%{
    transform: scale(0.5);
    opacity: 0;
  }
  100%{
    transform: scale(1.2);
    opacity: 1;
  }
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
}


</style>